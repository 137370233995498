// material-ui
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {MAIN_COLORS} from '../../constants';

const navbarStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolbar: {
      minHeight: '6.5vh',
      height: '6.5vh',
      backgroundColor: MAIN_COLORS.primary,
    },
    title: {
      flexGrow: 1,
      fontSize: '1.1em',
    },
    linkStyle: {
      color: 'white',
      textDecoration: 'none',
      paddingRight: 35,
    },
    switchThumb: {},
  }),
);

export default navbarStyles;
