const defaultCode = {
  'Room.java': [
    `public class Room {`,
    `   private double width, depth, height;`,
    `   private String textureTop,  textureBottom, textureEast, textureWest, textureSouth, textureNorth;`,
    ``,
    `   public Room() {`,
    `       width = 10;`,
    `       depth = 10;`,
    `       height = 10;`,
    `       textureBottom = "";`,
    `       textureTop = "";`,
    `       textureEast = "";`,
    `       textureWest = "";`,
    `       textureSouth = "";`,
    `       textureNorth = "";`,
    `   }`,
    `}`,
  ].join('\n'),

  'Planet.java': [
    `public class Planet {`,
    `    private double x = 5;`,
    `    private double y = 3;`,
    `    private double z = 5;`,
    `    private double scale = 2;`,
    `    private String model = "https://models.c3d.io/ap_Tutorial/assets/planet2.json";`,
    `}`,
  ].join('\n'),

  'Game.java': [
    `import env3d.Env;`,
    ``,
    `public class Game {`,
    `    private Env env;`,
    `    public void start() {`,
    `        env = new Env();`,
    `        env.setCameraXYZ(5, 5, 35);`,
    `        env.setRoom(new Room());`,
    `        env.addObject(new Planet());`,
    `        env.start();`,
    `    }`,
    `}`,
  ].join('\n'),
};

export default defaultCode;
