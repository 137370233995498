import React, {useEffect, useState} from 'react';

// material-ui
import Grid from '@material-ui/core/Grid';

// styles
import playgroundStyles from './playgroundStyles';

// components
import Editor from './Editor/Editor';
import Result from './Result/Result';
import transpile from '../../api/transpile';
import CustomError from '../Common/CustomError';

// others
import {launchCode, emptyCode} from '../../utils/configCode';

/** End of imports****** */
declare global {
  interface Window {
    game: any;
    THREE: any;
  }
}

const sessions = {};

//* Interfaces/types**********
type PlaygroundProps = {};
//* **************************

//* Return********************
const Playground: React.FC<PlaygroundProps> = () => {
  const classes = playgroundStyles();

  //* State*********************
  const [consoleErr, setConsoleErr] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  //* **************************

  //* Actions*****************

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleCustomError = (errMsgT: string) => {
    setOpenSnackBar(true);
    setErrMsg(errMsgT);
  };

  // Temp Fix to add lighting
  function addLightAfterTranspile() {
    try {
      const iFrame = document.querySelector('iframe');
      setTimeout(() => {
        if (window !== undefined && window.game && window.game.env) {
          window.game.env.scene.add(
            new iFrame!.contentWindow!.THREE!.HemisphereLight(
              0x7f7f7f,
              0x000000,
              4,
            ),
          );
        } else {
          handleCustomError('There was an error loading the world!');
        }
      }, 2000);
    } catch (err) {
      handleCustomError('Something went wrong!');
    }
  }

  const iFrameLoaded = (iFrame, scriptElement) => {
    iFrame!.contentDocument!.body.appendChild(scriptElement);
    addLightAfterTranspile();
  };

  const displayResult = (jsCode) => {
    try {
      const iFrame = document.getElementById(
        'outputIframe',
      ) as HTMLIFrameElement;

      iFrame!.contentWindow!.location.reload();

      iFrame.onload = () => {
        const scriptElement = document.createElement('script');
        // scriptElement.id = 'scriptElement';

        let code = '';
        if (!jsCode || jsCode.length === 0) {
          code = emptyCode;
        } else {
          code = jsCode + launchCode;
        }
        scriptElement.innerHTML = code;

        if (iFrame!.contentDocument!.body) {
          iFrame!.contentDocument!.body.appendChild(scriptElement);
          addLightAfterTranspile();
        } else {
          // if iframe's body has not been loaded, wait for it!
          iFrame.addEventListener('load', () =>
            iFrameLoaded(iFrame, scriptElement),
          );
        }
      };
    } catch (err) {
      handleCustomError('Something went wrong!');
    }
  };

  const sendToTranspile = () => {
    try {
      const finalCode = Object.keys(sessions).map((fileName) => {
        return {
          name: `${fileName}.java`,
          code: sessions[fileName].getDocument().getValue(),
        };
      });
      const finalCodeString = JSON.stringify(finalCode);
      transpile(finalCodeString)
        .then((jsCode) => {
          displayResult(jsCode);
        })
        .catch((err) => {
          // pushing the errors to console
          const errT = `<div><b>${Date()
            .toString()
            .slice(0, 24)}</b> <i>${err}</i> </div>`;
          setConsoleErr((prevState) => prevState + errT);
          setErrMsg('Something went wrong, Check Console!');
          setOpenSnackBar(true);
        });
    } catch (err) {
      setErrMsg('Something went wrong!');
      setOpenSnackBar(true);
    }
  };

  // const handleCodeInUrl = () => {
  // Check if the files are included in the URL
  // const inputCode = sharedCode;
  // console.log('here', inputCode);
  // if (inputCode.length > 2) {
  //   need to decode the base64 url
  //   localStorage.setItem(
  //     'java-code',
  //     atob(
  //       inputCode.replace(/\./g, '+').replace(/-/g, '=').replace(/_/g, '/'),
  //     ),
  //   );
  //   eslint-disable-next-line no-restricted-globals
  //   history.pushState(
  //     null,
  //     '',
  //     window.location.origin + window.location.pathname,
  //   );
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 100);
  // }
  // };

  //* ****************************

  //* Effects*********************
  useEffect(() => {
    sendToTranspile();
    // handleCodeInUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //* ****************************

  //* Return**********************
  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item sm={6} xs={12} className={classes.editor}>
          <Editor sendToTranspile={sendToTranspile} sessionsProp={sessions} />
        </Grid>
        <Grid item sm={6} xs={12} className={classes.result}>
          <Result consoleErr={consoleErr} />
        </Grid>
      </Grid>
      <CustomError
        openSnackBar={openSnackBar}
        errMsg={errMsg}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </div>
  );
};

export default Playground;
