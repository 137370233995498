const hosts = {
  heroku: 'https://c3d-backend.herokuapp.com/',
  local: 'http://localhost:3000/',
};

const loadCreations = async () => {
  const resp = await fetch(`${hosts.heroku}api/creations/all`);
  const data = await resp.json();

  return data;
};

export default loadCreations;
