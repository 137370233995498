const iframeSkelton = `<html>
<head>
  <script
    type="text/javascript"
    src="https://beta.c3d.io/js/inflate.min.js"
  ></script>
  <script
    type="text/javascript"
    src="https://beta.c3d.io/js/env3d.js"
  ></script>
  <script
    type="text/javascript"
    src="https://arsh-uppal.github.io/bundle-js/bundle.js"}
  ></script>
  <script>
    let info = console.info;
    console.info = function (msg) {
      info(msg);
      parent.window.postMessage(msg, '*');
    };
  </script>
  <style>
    * {
      margin: 0;
      padding: 0;
      overflow:hidden;
    }
  </style>
</head>
<body>
  <div id="env3d"></div>
</body>
</html>`;

export default iframeSkelton;
