// material-ui
import {createStyles, makeStyles} from '@material-ui/core/styles';

const saveCodeStyles = makeStyles(() =>
  createStyles({
    btns: {
      marginRight: 25,
      fontSize: 10,
      fontWeight: 'bold',
    },
  }),
);

export default saveCodeStyles;
