/* eslint-disable no-shadow */
import React from 'react';
import Button from '@material-ui/core/Button';

import saveCodeStyles from './handleCodeStyles';

//* Interfaces/types**********
type HandleCodeProps = {
  handleCustomError: (errMsg: string) => void;
};
//* **************************

//* Return*********************
const HandleCode: React.FC<HandleCodeProps> = () => {
  const classes = saveCodeStyles();

  const handleCodeDownload = () => {
    const currentCode =
      localStorage.getItem('java-code') || 'something went wrong';

    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,${encodeURIComponent(currentCode)}`,
    );

    // temporary - add a better popup later
    // eslint-disable-next-line no-alert
    const fileName = prompt('Choose a file name', 'c3dJava');

    if (fileName) {
      element.setAttribute('download', fileName);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  const handlCodeLoad = async (e) => {
    e.preventDefault();
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const javaCode: any = e!.target!.result;
        localStorage.setItem('java-code', javaCode?.toString());

        setTimeout(() => {
          // once we have the latest code, reload page
          // reload is deprecated, find a better way in futre
          window.location.reload(false);
        }, 200);
      };
      reader.readAsText(e.target.files[0]);
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert('Something went wrong !!');
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        className={classes.btns}
        component="label">
        Load
        <input
          onChange={(e) => handlCodeLoad(e)}
          type="file"
          style={{display: 'none'}}
          accept="text/plain"
        />
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.btns}
        onClick={handleCodeDownload}>
        Download
      </Button>
    </>
  );
};

export default HandleCode;
