import React from 'react';

// material-ui
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';

//* Snackbar Alert*************
function Alert(props: AlertProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
//* **************************

type CustomErrorProps = {
  openSnackBar: boolean;
  errMsg: string;
  handleCloseSnackBar: (event?: React.SyntheticEvent, reason?: string) => void;
};

const CustomError: React.FC<CustomErrorProps> = ({
  openSnackBar,
  errMsg,
  handleCloseSnackBar,
}: CustomErrorProps) => {
  return (
    <Snackbar
      open={openSnackBar}
      autoHideDuration={6000}
      onClose={handleCloseSnackBar}>
      <Alert onClose={handleCloseSnackBar} severity="error">
        {errMsg}
      </Alert>
    </Snackbar>
  );
};

export default CustomError;
