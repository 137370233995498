/**
 * The choice to handle both login and signup at one place is debatable.
 */

import React, {useState, useContext} from 'react';

import {useHistory} from 'react-router-dom';

// material-ui
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

// components
import Copyright from '../Common/Copyright';
import CustomError from '../Common/CustomError';
import {FirebaseContext} from '../Firebase';

// api
// import addNewSubscriber from '../../api/handleSubscribers';

// styles
import authStyles from './authStyles';

//* Interfaces/types**********
type AuthProps = {
  handleUserSignIn: (signedIn) => void;
};
//* **************************

let signInFormFlag = true;
const Auth: React.FC<AuthProps> = ({handleUserSignIn}: AuthProps) => {
  const firebase = useContext(FirebaseContext);
  const history = useHistory();
  //* State********************
  const userTypes = ['Teacher', 'Student'];
  const [signInForm, setSignInForm] = useState(signInFormFlag);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    userType: userTypes[0],
  });
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogBody, setDialogBody] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [resendEmailError, setResendEmailError] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  //* **************************

  //* Actions*****************

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogOk = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (event: any) => {
    const {name, value} = event.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  // toggle between SignIn & SignUp forms
  const handleFormChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    const formType = event.currentTarget.getAttribute('form-type');
    if (formType === 'signin') {
      setSignInForm(false);
      signInFormFlag = false; // to avoid resetting when rerendering
    } else {
      setSignInForm(true);
      signInFormFlag = true; //  ^
    }
  };

  // WARNING
  // Move this method out of here to the api
  // Resend the email verification email
  const resendEmailVerification = () => {
    try {
      const user = firebase.getCurrentUser();
      user
        .sendEmailVerification()
        .then(() => {
          setShowSuccessMsg(true);
        })
        .catch(() => {
          setErrMsg('An error occured, try again later !');
          setOpenSnackBar(true);
          setShowSuccessMsg(false);
        });
    } catch (err) {
      setErrMsg('An error occured, try again later !');
      setOpenSnackBar(true);
    }
  };

  // WARNING
  // Move this method out of here to the api
  // sign in to an existing account
  const userSignin = () => {
    firebase
      .signInWithEmailAndPassword(formData.email, formData.password)
      .then((authUser) => {
        const {user} = authUser;
        if (user.emailVerified) {
          localStorage.setItem('user', user.displayName);
          localStorage.setItem('email', user.email);
          handleUserSignIn(true);
          history.push('/playground');
        } else {
          setErrMsg('Please verify your email!!');
          handleUserSignIn(false);
          setOpenSnackBar(true);
          setResendEmailError(true);
        }
      })
      .catch((err) => {
        setErrMsg(err.message);
        setOpenSnackBar(true);
      });
  };

  // WARNING
  // Move this method out of here to the api
  // create a new account
  const createUserAccount = () => {
    firebase
      .createUserWithEmailAndPassword(formData.email, formData.password)
      .then(() => {
        const user = firebase.getCurrentUser();
        user
          .updateProfile({
            // temp fix, storing the usertype with the name
            // Firebase only allow to store displayName and profile pic
            displayName: `${formData.firstName}-${formData.lastName}-${formData.userType}`,
          })
          .then(() => {
            setDialogTitle('Account created');
            setDialogBody(
              'Please verify your email before your start using your account',
            );
            // TO FIX : Dynamically add user to the API kit. Getting some error with the form ID
            // addNewSubscriber(formData.email);
            setOpenDialog(true);
          })
          .catch(() => {
            setErrMsg('An error occured while saving your information!');
            setOpenSnackBar(true);
          });
      })
      .catch((err) => {
        setErrMsg(err.message);
        setOpenSnackBar(true);
      });
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    // checking if the current form is signup form or a signin form
    if (!signInForm) {
      createUserAccount(); // signup form
    } else {
      userSignin(); // signin form
    }
  };

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleGoogleLogin = () => {
    firebase
      .googleSignIn()
      .then((authUser) => {
        const {user} = authUser;
        if (user.emailVerified) {
          localStorage.setItem('user', user.displayName);
          localStorage.setItem('email', user.email);
          handleUserSignIn(true);
          history.push('/playground');
        } else {
          setErrMsg('Please verify your email!!');
          handleUserSignIn(false);
          setOpenSnackBar(true);
          setResendEmailError(true);
        }
      })
      .catch((err) => {
        setErrMsg(err.message);
        setOpenSnackBar(true);
      });
  };

  const handleForgetPassword = () => {
    // eslint-disable-next-line no-alert
    const email = prompt('Please enter your email');
    firebase
      .passwordReset(email)
      .then(() => {
        // eslint-disable-next-line no-alert
        alert('Please check your email for a reset link!');
      })
      .catch((err) => {
        setErrMsg(err.message);
        setOpenSnackBar(true);
      });
  };

  //* **************************
  const classes = authStyles();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {signInForm ? 'Sign in' : 'Sign up'}
        </Typography>
        <form className={classes.form} onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            {!signInForm && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                    onChange={handleInputChange}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                type="email"
                autoComplete="email"
                autoFocus={signInForm}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleInputChange}
                inputProps={{minLength: 6}}
              />
            </Grid>
            {!signInForm && (
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    You are a *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="userType"
                    value={formData.userType}
                    onChange={handleInputChange}
                    label="Select one">
                    {userTypes.map((type) => (
                      <MenuItem value={type} key={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}>
            {signInForm ? 'Sign In' : 'Sign up'}
          </Button>
          {signInForm && (
            <Button
              fullWidth
              variant="contained"
              className={classes.googleLogin}
              color="secondary"
              onClick={handleGoogleLogin}>
              Google Login
            </Button>
          )}
          <Grid container justify="flex-end">
            <Grid item>
              <Button
                color="primary"
                onClick={handleFormChange}
                className={classes.actionBtn}
                form-type={signInForm ? 'signin' : 'signup'}>
                {signInForm
                  ? `Don't have an account? Sign Up`
                  : 'Already have an account? Sign in'}
              </Button>
            </Grid>
            <Grid item>
              {resendEmailError && (
                <Button
                  color="primary"
                  onClick={resendEmailVerification}
                  className={classes.resendEmail}>
                  Resend Email verification link to your email?
                </Button>
              )}
            </Grid>
            {showSuccessMsg && (
              <Alert severity="success" className={classes.resendEmailMsg}>
                Verification email was sent successfully
              </Alert>
            )}
          </Grid>

          {signInForm && (
            <Button
              color="primary"
              onClick={handleForgetPassword}
              className={classes.actionBtn}
              style={{float: 'right'}}>
              Forget Password?
            </Button>
          )}
        </form>
      </div>

      {/* Toast - try making this a common component. However make sure you don't cause unwanted rerenders in the process !!!! For example - don't pass down the shared toast component!!! */}

      <CustomError
        openSnackBar={openSnackBar}
        errMsg={errMsg}
        handleCloseSnackBar={handleCloseSnackBar}
      />

      {/* Dialog - try making this a common component */}

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogBody}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogOk} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Auth;
