import React, {useState, useEffect} from 'react';

// material-ui
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// others
import {themes, fontSizes} from '../../../../utils/editorConfig';

// styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '120px',
      height: '45px',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    editorConfigContainer: {
      clear: 'both',
    },
    selectBox: {
      height: '45px',
    },
  }),
);

//* Interfaces/types**********
type EditorConfigProps = {
  updateTheme: (theme: string) => void;
  updateFontSize: (fontSize: number) => void;
};
//* **************************

//* Return*********************
const EditorConfig: React.FC<EditorConfigProps> = ({
  updateTheme,
  updateFontSize,
}: EditorConfigProps) => {
  const classes = useStyles();

  //* State***********************
  const [theme, setTheme] = useState(localStorage.getItem('editor-theme'));
  const [fontSize, setFontSize] = useState(
    localStorage.getItem('editor-font-size'),
  );
  //* ****************************

  //* Actions*********************
  const updateLocalStorage = (name, val) => {
    localStorage.setItem(name, val);
  };

  const handleChange = (
    event: React.ChangeEvent<{name?: string; value: any}>,
  ) => {
    const {name, value} = event.target;
    if (name === 'theme') {
      setTheme(value);
      updateLocalStorage('editor-theme', value);
      updateTheme(value);
    } else {
      setFontSize(value);
      updateLocalStorage('editor-font-size', value);
      updateFontSize(value);
    }
  };

  const loadThemes = () => {
    const optionArr: any = [];
    themes.map((themeT) => {
      optionArr.push(<option value={themeT}>{themeT}</option>);
      return null;
    });
    return optionArr;
  };

  const loadFontSizes = () => {
    const optionArr: any = [];
    fontSizes.map((fontSizeT) => {
      optionArr.push(<option value={fontSizeT}>{fontSizeT}</option>);
      return null;
    });
    return optionArr;
  };
  //* **************************

  //* UseEffect*****************
  useEffect(() => {
    if (
      !localStorage.getItem('editor-theme') ||
      localStorage.getItem('editor-theme')?.length === 0
    ) {
      localStorage.setItem('editor-theme', themes[0]);
    }
    if (
      !localStorage.getItem('editor-font-size') ||
      localStorage.getItem('editor-font-size')?.length === 0
    ) {
      localStorage.setItem('editor-font-size', fontSizes[0].toString());
    }
  }, []);
  //* **************************

  return (
    <div className={classes.editorConfigContainer}>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-theme-native-simple">Theme</InputLabel>
        <Select
          native
          value={theme}
          onChange={handleChange}
          inputProps={{
            name: 'theme',
            id: 'filled-theme-native-simple',
          }}
          className={classes.selectBox}>
          {loadThemes()}
        </Select>
      </FormControl>

      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-theme-native-simple">Font Size</InputLabel>
        <Select
          native
          value={fontSize}
          onChange={handleChange}
          inputProps={{
            name: 'fontSize',
            id: 'filled-font-native-simple',
          }}
          className={classes.selectBox}>
          {loadFontSizes()}
        </Select>
      </FormControl>
    </div>
  );
};

export default EditorConfig;
