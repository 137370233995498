/* eslint-disable react/self-closing-comp */
import React, {useState} from 'react';

import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
// import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
// import clsx from 'clsx';
// import Collapse from '@material-ui/core/Collapse';
// import Typography from '@material-ui/core/Typography';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import {red} from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
// import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      maxHeight: 10,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    iframeOutput: {
      backgroundColor: 'black',
      height: 40,
      padding: 1,
      width: '97%',
    },
  }),
);

type CreationLayoutProps = {
  creation: any;
};

const CreationLayout: React.FC<CreationLayoutProps> = ({
  creation,
}: CreationLayoutProps) => {
  const classes = useStyles();
  // const [expanded, setExpanded] = useState(false);
  const [favtColor, setFavtColor] = useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  const formatDate = (date) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    return new Date(date).toLocaleDateString('en-US', options);
  };

  // WARNING
  // MOVE THIS OUT OF HERE
  const urlArr = window.location.href.split('/');
  const current = {
    urlLocal: `${urlArr[0]}//${urlArr[2]}/playground`,
    urlProd: 'https://java.c3d.io/',
    urlGit: 'https://arsh-uppal.github.io/c3d-java-frontend-react/',
  };

  const getUserAvatar = (name) => {
    return name.slice(0, 1).toUpperCase();
  };

  const handleFavt = () => {
    setFavtColor(!favtColor);
  };

  // const imgUrl = () => {
  //   const randNum = Math.floor(Math.random() * 6) + 1;
  //   return `https://source.unsplash.com/random?sig=${randNum}`;
  // };

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {getUserAvatar(creation.userName)}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={creation.creationName}
        subheader={formatDate(creation.creationDate)}
        style={{padding: 0}}
      />
      {/* <CardContent className={classes.iframeOutput}>
        <CardMedia image={imgUrl()} title="Paella dish" style={{height: 30}} />
      </CardContent> */}
      <CardActions disableSpacing style={{float: 'right'}}>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon
            style={{color: favtColor ? 'red' : ''}}
            onClick={handleFavt}
          />
        </IconButton>
        <Button
          variant="outlined"
          color="primary"
          href={`${current.urlProd}#${creation.creationCode}`}
          target="_blank"
          size="small">
          Load
        </Button>
        {/* <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more">
          <ExpandMoreIcon />
        </IconButton> */}
      </CardActions>
      {/* <Collapse style={{padding: 0}} in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            Creation By: {creation.userName.split('-')[0].toUpperCase()}
          </Typography>
        </CardContent>
      </Collapse> */}
    </Card>
  );
};

export default CreationLayout;
