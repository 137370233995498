/* eslint-disable react/jsx-props-no-spreading */
import React, {useState, useEffect} from 'react';

// Ace editor
import AceEditor from 'react-ace';
import ace from 'ace-builds/src-noconflict/ace';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

// material-ui
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';

// styles
import editorStyles from './editorStyles';

// components
import Files from './Files/Files';

// Others
import defaultCode from '../../../utils/defaultCode';
import CustomError from '../../Common/CustomError';

//* End of Imports************

//* Interfaces/types**********
type EditorProps = {
  sendToTranspile: () => void;
  sessionsProp: any;
};
//* **************************

//* Return*********************
const Editor: React.FC<EditorProps> = ({
  sendToTranspile,
  sessionsProp,
}: EditorProps) => {
  const classes = editorStyles();
  const sessions = sessionsProp;

  //* State*********************
  const [tabs, setTabs] = useState({});
  const [activeTab, setActiveTab] = useState('first');
  const [theme, setTheme] = useState('monokai');
  const [fontSize, setFontSize] = useState(14);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  //* **************************

  //* Actions*******************

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleCustomError = (errMsgT: string) => {
    setOpenSnackBar(true);
    setErrMsg(errMsgT);
  };

  const updateLocalStorage = (javaCode) => {
    try {
      localStorage.setItem('java-code', JSON.stringify(javaCode));
    } catch (err) {
      handleCustomError('Something went wrong!');
    }
  };

  const saveCurretCodeToLocalStorage = () => {
    try {
      const currentCode = {};
      // eslint-disable-next-line array-callback-return
      Object.keys(sessions).map((fileName) => {
        currentCode[fileName] = sessions[fileName].getDocument().getValue();
      });
      updateLocalStorage(currentCode);
    } catch (err) {
      handleCustomError('There was a problem saving code in your browser!');
    }
  };

  const switchSession = (className: string) => {
    try {
      const editor = ace.edit('editor');
      editor.setSession(sessions[className]);
      editor.gotoLine(2, 0);
      setActiveTab(className);
      // update the local storage
      saveCurretCodeToLocalStorage();
    } catch (err) {
      handleCustomError('There was a problem opening the selected file!');
    }
  };

  const createNewTab = (className: string) => {
    try {
      const fileName = `${className}.java`;
      const newTab = (
        <Tab
          label={fileName}
          value={className}
          key={className}
          classes={{
            root: classes.tab,
            selected: classes.selectedTab,
          }}
        />
      );

      setTabs((prevState) => {
        return {
          ...prevState,
          [className]: newTab,
        };
      });

      switchSession(className);
    } catch (err) {
      handleCustomError('There was a problem creating a new tab');
    }
  };

  const handleNewFile = (fileName: string) => {
    try {
      let fileNameJ = fileName.split('.')[0]; // J signifies java
      const className =
        fileNameJ.charAt(0).toUpperCase() + fileNameJ.slice(1).toLowerCase();
      if (!sessions[className]) {
        fileNameJ += '.java';
        const session = new ace.EditSession('editor', 'ace/mode/java');
        session.setValue(`public class ${className} {\n\n}`);
        sessions[className] = session;
        // create a new tab
        createNewTab(className);
      } else {
        handleCustomError('File with the same name already exist!');
      }
    } catch (err) {
      handleCustomError('There was a problem adding new file!');
    }
  };

  const handleTabChange = (tabIndex: string) => {
    switchSession(tabIndex);
  };

  const loadCodeInEditor = () => {
    try {
      setTabs({}); // clear all the tabs
      // iterate over all sessions and create tabs for them
      const javaCode = JSON.parse(localStorage.getItem('java-code') || '{}');
      Object.keys(javaCode).map((fileName) => {
        sessions[fileName.split('.')[0]] = ace.createEditSession(
          javaCode[fileName],
          'ace/mode/java',
        );
        return null;
      });

      Object.keys(javaCode).map((fileName) => {
        const className = fileName.split('.')[0];
        createNewTab(className);
        return null;
      });
    } catch (err) {
      handleCustomError('Something Went wrong!');
    }
  };

  // on editor load
  const onLoad = () => {
    try {
      if (
        !localStorage.getItem('java-code') ||
        localStorage.getItem('java-code')?.length === 0 ||
        localStorage.getItem('java-code')?.length === 2
      ) {
        updateLocalStorage(defaultCode);
      }
      loadCodeInEditor();
    } catch (err) {
      handleCustomError('Please enable local-storage and try again/refresh!');
    }
  };

  // update the localstorage with latest code on change (debouncing)
  let updateTimer;
  const onChange = () => {
    try {
      if (updateTimer) {
        clearTimeout(updateTimer);
      }
      updateTimer = setTimeout(() => {
        saveCurretCodeToLocalStorage();
      }, 100);
    } catch (err) {
      handleCustomError('Something went wrong!');
    }
  };

  const deleteFile = (fileName: string) => {
    try {
      if (fileName !== 'Game') {
        delete sessions[fileName];
        saveCurretCodeToLocalStorage();
        loadCodeInEditor();
      } else {
        handleCustomError('Game class is required to run C3D Java programs!');
      }
    } catch (err) {
      handleCustomError('Something went wrong while deleting the file!');
    }
  };

  const updateTheme = (themeT: string) => {
    setTheme(themeT);
  };

  const updateFontSize = (fontSizeT: number) => {
    // typeof fontSizeT prints string when you console it ??why
    // however when I put it in parseInt, it gives me an type error
    setFontSize(parseInt(fontSizeT.toString(), 10));
  };

  //* *************************

  //* Effects******************
  //* UseEffect*****************
  useEffect(() => {
    if (localStorage.getItem('editor-theme')) {
      setTheme(localStorage.getItem('editor-theme') || 'monokai');
    }
    if (localStorage.getItem('editor-font-size')) {
      const fontSizeT = localStorage.getItem('editor-font-size');
      const val = fontSizeT !== null ? parseInt(fontSizeT, 10) : 14;
      setFontSize(val);
    }
  }, []);
  //* **************************
  //* *************************

  //* Return*******************
  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={12} className={classes.files}>
        <Files
          handleNewFile={handleNewFile}
          deleteFile={deleteFile}
          handleTabChange={handleTabChange}
          sendToTranspile={sendToTranspile}
          tabs={tabs}
          activeTab={activeTab}
          updateTheme={updateTheme} // for now the settings are in files popper
          updateFontSize={updateFontSize} // ^
        />
      </Grid>
      <Grid item xs={12} className={classes.editor}>
        <AceEditor
          mode="java"
          theme={theme}
          name="editor"
          onLoad={onLoad}
          onChange={onChange}
          fontSize={fontSize}
          showPrintMargin
          showGutter
          highlightActiveLine
          height="100%"
          width="100%"
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 4,
          }}
          className={classes.aceEditor}
        />
      </Grid>
      <CustomError
        openSnackBar={openSnackBar}
        errMsg={errMsg}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Grid>
  );
};

export default Editor;

// https://www.npmjs.com/package/react-ace
