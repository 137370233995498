// material-ui
import {createStyles, makeStyles} from '@material-ui/core/styles';

const galleryStyles = makeStyles(() =>
  createStyles({
    creationsContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '4%',
      flexWrap: 'wrap',
      '& div': {
        margin: 10,
      },
    },
  }),
);

export default galleryStyles;
