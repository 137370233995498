// material-ui
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import {FILE_COLORS} from '../../../constants';

const editorStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    files: {
      height: '6%',
    },
    tab: {
      minHeight: '30px',
      borderLeft: `0.1px solid ${FILE_COLORS.tabBorderColor}`,
      borderRight: `0.1px solid ${FILE_COLORS.tabBorderColor}`,
      color: FILE_COLORS.tabsColor,
    },
    selectedTab: {
      backgroundColor: FILE_COLORS.selectedTabBgColor,
      borderLeft: `0.1px solid ${FILE_COLORS.tabBorderColor}`,
      borderRight: `0.1px solid ${FILE_COLORS.tabBorderColor}`,
      color: `${FILE_COLORS.selectedTabColor}!important`,
    },
    editor: {height: '94%'},
    aceEditor: {
      resize: 'horizontal',
      minWidth: '100%',
      maxWidth: '160%',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        resize: 'none',
      },
    },
  }),
);

export default editorStyles;
