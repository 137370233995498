import {v4 as uuidv4} from 'uuid';

declare global {
  interface Window {
    ts: any;
  }
}

const uniqueId = uuidv4();

const transpile = (javaCode) => {
  const formData = new FormData();
  formData.append('javaCode', javaCode);
  formData.append('tsout', 'true');
  formData.append('tid', uniqueId);

  const url = 'https://transpile.c3d.io/transpile';

  return new Promise((resolve, reject) => {
    try {
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
            // eslint-disable-next-line no-else-return
          } else {
            reject(response);
          }
        })
        .then((json) => {
          const opts = {
            module: 1,
            target: 1,
            noLib: true,
            noResolve: true,
            suppressOutputPathCheck: true,
          };
          const jsonT = json;
          jsonT.jsout = window.ts.transpile(jsonT.tsout, opts);
          if (jsonT.errors && jsonT.errors.length > 0) {
            reject(jsonT.errors);
          } else {
            resolve(jsonT.jsout);
          }
        })
        .catch((error) => {
          reject(new Error(`Error with transpiling ${error}`));
        });
    } catch (error) {
      reject(new Error(`Error with transpiling ${error}`));
    }
  });
};

export default transpile;
