import React, {useContext} from 'react';

// material-ui
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SettingsPowerSharpIcon from '@material-ui/icons/SettingsPowerSharp';

// router
import {Link, useHistory} from 'react-router-dom';

// components
import HandleCode from '../HandleCode/HandleCode';
import {FirebaseContext} from '../Firebase';

// styles
import navbarStyles from './navbarStyles';

// constants

import {JAVA_LESSONS} from '../../constants';

//* Interfaces/types**********
type NavBarProps = {
  handleCustomError: (errMsg: string) => void;
  handleUserSignIn: (signedIn) => void;
  user: boolean;
};
//* **************************

//* Return*********************
const NavBar: React.FC<NavBarProps> = ({
  handleCustomError,
  handleUserSignIn,
  user,
}: NavBarProps) => {
  const classes = navbarStyles();
  const firebase = useContext(FirebaseContext);
  const history = useHistory();

  // const [state, setState] = React.useState({
  //   checkedA: true,
  //   checkedB: true,
  // });

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setState({...state, [event.target.name]: event.target.checked});
  // };

  const handleLogout = () => {
    try {
      handleUserSignIn(false);
      localStorage.removeItem('user');
      firebase.signOut();
      history.push('/');
    } catch (err) {
      handleCustomError('Something went wrong');
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" className={classes.title}>
            C3D Java
          </Typography>
          {user && (
            <>
              <Link to="/playground" className={classes.linkStyle}>
                Home
              </Link>
              <a
                href={JAVA_LESSONS}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.linkStyle}>
                Lessons
              </a>
              <Link to="/gallery" className={classes.linkStyle}>
                Gallery
              </Link>
              <HandleCode handleCustomError={handleCustomError} />
              <IconButton
                color="primary"
                aria-label="handles logout"
                onClick={handleLogout}>
                <SettingsPowerSharpIcon style={{color: 'white'}} />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;
