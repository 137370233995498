/* eslint-disable react/self-closing-comp */
/* eslint-disable no-alert */
/* eslint-disable guard-for-in */
/* eslint-disable react/no-danger */
import React, {useState} from 'react';

import iframeSkelton from './env3dHtml';

// material-ui
import IconButton from '@material-ui/core/IconButton';
import CancelPresentationTwoToneIcon from '@material-ui/icons/CancelPresentationTwoTone';
import OpenInBrowserTwoToneIcon from '@material-ui/icons/OpenInBrowserTwoTone';
import ShareTwoToneIcon from '@material-ui/icons/ShareTwoTone';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import Popper from '@material-ui/core/Popper';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

// HTML parser
import ReactHtmlParser from 'react-html-parser';

// JQuery
import $ from 'jquery';

// styles
import resultStyles from './resultStyles';

type ResultProps = {
  consoleErr: any;
};

const Result: React.FC<ResultProps> = ({consoleErr}: ResultProps) => {
  const classes = resultStyles();

  //* State*********************
  const [showConsole, setShowConsole] = useState(false);
  //* **************************

  //* Popper**********************
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [shareUrl, setShareUrl] = useState('');
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [creationNameT, setCreationName] = useState('');
  const [shareCodeURLState, setShareCodeURLState] = useState('');
  const [nameError] = useState(false);
  const [errorDesc] = useState('');

  const hideSuccessMsg = () => {
    setTimeout(() => {
      setShowSuccessMsg(false);
    }, 2000);
  };

  // This code could be reused ^
  const hideErrMsg = () => {
    setTimeout(() => {
      setShowErrMsg(false);
    }, 2000);
  };

  // WARNING
  // Move this method out of here to the api
  const handleShare = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);

    const url = 'https://share.c3d.io/api.php';

    const javaCode: any = localStorage.getItem('java-code');

    const shareCodeURL = `${
      window.location.origin + window.location.pathname
    }#code=${btoa(javaCode)
      .replace(/\+/g, '.')
      .replace(/=/g, '-')
      .replace(/\//g, '_')}`;

    setShareCodeURLState(shareCodeURL);

    const params = {
      action: 'shorturl',
      format: 'json',
      url: shareCodeURL,
    };

    // Legacy JQuery code
    // Change it to a fetch in the future
    const response = $.post(url, params, (data) => {
      setShareUrl(data.shorturl);
    });

    // just in case the shortener is not working
    response.fail(() => {
      setShareUrl('Something went wrong!!');
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const closePopper = () => {
    setAnchorEl(null);
  };

  // Resets the code
  const handleReset = () => {
    // eslint-disable-next-line no-restricted-globals
    const res = confirm('Reset code? You will lose all your progress!!');
    if (res) {
      localStorage.removeItem('java-code');
      window.location.reload();
    }
  };
  //* **************************

  //* Actions*******************
  const toggleConsole = () => {
    setShowConsole(!showConsole);
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setSuccessMsg('Url copied successfully.');
      setShowSuccessMsg(true);
      hideSuccessMsg();
    });
  };

  const handleChange = (event) => {
    setCreationName(event.target.value);
  };

  // WARNING
  // Move this out of here to an env file
  const hosts = {
    heroku: 'https://c3d-backend.herokuapp.com/',
    local: 'http://localhost:3000/',
  };

  // WARNING
  // Move this method out of here
  // Adds creatiosn to the database
  const handleCommunityShare = (event) => {
    event.preventDefault();

    try {
      const user = localStorage.getItem('user');
      const email = localStorage.getItem('email');
      const creationCodeT = shareCodeURLState.split('#')[1];
      const params = {
        userEmail: email,
        userName: user,
        creationName: creationNameT,
        creationCode: creationCodeT,
      };

      let formBody: any = [];

      // TO BE FIXED : Avoid using For-in loop
      // eslint-disable-next-line no-restricted-syntax
      for (const property in params) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(params[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
      }

      formBody = formBody.join('&');

      fetch(`${hosts.heroku}api/creations/add`, {
        method: 'POST',
        body: formBody,
        headers: {'Content-type': 'application/x-www-form-urlencoded'},
      })
        .then((response) => {
          if (response.status !== 200) {
            setErrMsg('Sharing Failed');
            setShowErrMsg(true);
            hideErrMsg();
          }
          return response.json();
        })
        .then((json) => {
          if (json === 'success') {
            setSuccessMsg('Shared successfully.');
            setShowSuccessMsg(true);
            hideSuccessMsg();
          } else {
            setErrMsg('Sharing Failed!');
            setShowErrMsg(true);
            hideErrMsg();
          }
        })
        .catch(() => {
          setErrMsg('Sharing Failed!');
          setShowErrMsg(true);
          hideErrMsg();
        });
    } catch (err) {
      setErrMsg('Something went wrong, try again later');
      setShowErrMsg(true);
      hideErrMsg();
    }
  };

  //* **************************

  return (
    <div className={classes.root}>
      <iframe
        srcDoc={iframeSkelton}
        id="outputIframe"
        style={{height: '100%', width: '100%'}}
        className={classes.iframe}
        title="output frame"
        name="main frame"></iframe>
      {/* Share Button */}
      <IconButton
        className={classes.shareBtn}
        onClick={handleShare}
        title="Share">
        <ShareTwoToneIcon />
      </IconButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={classes.popper}>
        <div className={classes.popperContainer}>
          <IconButton
            aria-label="close"
            className={classes.popperCloseBtn}
            onClick={closePopper}>
            <CancelPresentationTwoToneIcon />
          </IconButton>
          <div className={classes.copyContainer}>
            <span> {shareUrl} </span>
            <IconButton onClick={copyUrl}>
              <FileCopyTwoToneIcon />
            </IconButton>
          </div>

          <div>
            <form onSubmit={handleCommunityShare} className={classes.form}>
              <TextField
                error={nameError}
                id="outlined-error-helper-text"
                label="Your creation name"
                defaultValue={creationNameT}
                helperText={nameError && errorDesc}
                variant="outlined"
                onChange={handleChange}
                size="small"
                InputLabelProps={{
                  style: {
                    fontSize: '13px',
                  },
                }}
                required
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{marginTop: 10}}>
                Share with community
              </Button>
            </form>
          </div>

          {showSuccessMsg && <Alert severity="success">{successMsg}</Alert>}
          {showErrMsg && <Alert severity="error">{errMsg}</Alert>}
        </div>
      </Popper>
      {/* Console */}
      {showConsole && (
        <div className={classes.console}>
          <Chip label="Console" />
          <div className={classes.errMsg}>
            {/* Maybe Find a better solution */}
            {ReactHtmlParser(consoleErr)}
          </div>
        </div>
      )}
      {/* Close Console */}
      {showConsole && (
        <IconButton
          aria-label="close"
          className={classes.consCloseBtn}
          onClick={toggleConsole}>
          <CancelPresentationTwoToneIcon />
        </IconButton>
      )}
      {/* Open Console */}
      <IconButton
        className={classes.openConsoleBtn}
        onClick={toggleConsole}
        title="Console">
        <OpenInBrowserTwoToneIcon />
      </IconButton>
      {/* Reset Button */}
      <IconButton
        className={classes.resetBtn}
        onClick={handleReset}
        title="Reset everything">
        <ErrorOutlineOutlinedIcon />
      </IconButton>
    </div>
  );
};

export default Result;
