// material-ui
import {makeStyles, Theme} from '@material-ui/core/styles';

const resultStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  iframe: {
    border: 'none',
  },
  console: {
    height: '200px',
    backgroundColor: 'white',
    position: 'fixed',
    bottom: 0,
    width: '50%',
    padding: 8,
    paddingTop: 20,
    overflow: 'auto',
  },
  shareBtn: {
    position: 'fixed',
    top: '7%',
    left: '50%',
    zIndex: 10,
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  consCloseBtn: {
    position: 'fixed',
    bottom: 175,
    right: 0,
  },
  openConsoleBtn: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: 10,
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  resetBtn: {
    position: 'fixed',
    bottom: 0,
    left: '50%',
    zIndex: 10,
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  errMsg: {
    padding: 20,
    color: 'red',
  },
  popper: {
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 40,
    maxWidth: 400,
    maxHeight: 500,
    minWidth: 200,
    minHeight: 200,
  },
  popperContainer: {
    border: '1px solid',
    backgroundColor: theme.palette.background.paper,
    padding: '20px',
  },
  popperCloseBtn: {
    float: 'right',
    left: '20px',
    top: '-10px',
  },
  copyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100% ',
  },
  form: {
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 5,
  },
}));

export default resultStyles;
