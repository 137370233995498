export const launchCode = [
  'env3d.Env.baseAssetsUrl = "https://env3d.github.io/env3d-js/dist/";',
  'var game = new Game();',
  'parent.window["game"] = game;',
  // This following line is a hack to add some basic lighting
  //    -- really need to rethink this technically as well as pedagogically
  'game.env && game.env.scene ',
  '         && game.env.scene.add(new THREE.HemisphereLight( 0x7f7f7f, 0x000000, 5 ));',
  'game.start();',
  'window.setInterval(game.loop.bind(game), 32);',
].join('\n');

export const emptyCode = [
  'env3d.Env.baseAssetsUrl = "https://env3d.github.io/env3d-js/dist/";',
  'let env = new env3d.Env()',
  'env.start();',
].join('\n');
