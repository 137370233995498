import React from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CodeIcon from '@material-ui/icons/Code';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 752,
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
  }),
);

//* Interfaces/types**********
type FilesListProps = {
  deleteFile: (fileName: string) => void;
  fileNames: any;
};
//* ***************************

//* Return*********************
const FilesList: React.FC<FilesListProps> = ({
  deleteFile,
  fileNames,
}: FilesListProps) => {
  const classes = useStyles();

  //* Actions*********************
  const handleFileDelete = (fileName: string) => {
    // eslint-disable-next-line no-alert
    const del = window.confirm('Are you sure you want to delete the file?');
    if (del) {
      deleteFile(fileName);
    }
  };

  const fileNamesList = () => {
    const fileNameArr: any = [];
    fileNames.forEach((fileName) => {
      fileNameArr.push(
        <List key={`key_${fileName}`}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <CodeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={fileName} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleFileDelete(fileName)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </List>,
      );
    });
    return fileNameArr;
  };

  //* ****************************

  return (
    <div>
      <div className={classes.demo}>{fileNamesList()}</div>
    </div>
  );
};

export default FilesList;
