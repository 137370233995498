/* eslint-disable import/prefer-default-export */
const MAIN_COLORS = {
  primary: '#272822',
  secondary: '#2F3129',
  accent: '#E6DB74',
  notFocussd: '#d4d4d4',
};

const FILE_COLORS = {
  tabsColor: '#d4d4d4',
  tabsArrowColor: '#ffffff',
  tabBorderColor: '#272822',

  selectedTabBgColor: '#272822',
  selectedTabColor: '#ffffff',
  selectedTabBorderColor: '#3d4035',

  scrollBtnBgColor: '#3d4035',
};

export {MAIN_COLORS, FILE_COLORS};
