// material-ui
import {makeStyles, Theme} from '@material-ui/core/styles';
import {FILE_COLORS, MAIN_COLORS} from '../../../../constants';

const fileStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    alignContent: 'start',
    position: 'relative',
  },
  tabsContainer: {height: '100%'},
  tabsAppBar: {
    height: '100%',
    backgroundColor: MAIN_COLORS.secondary,
  },
  settingBtn: {
    minWidth: '40px',
    height: '100%',
    position: 'absolute',
    left: '0',
    backgroundColor: MAIN_COLORS.secondary,
    color: MAIN_COLORS.notFocussd,
    '&:hover': {
      backgroundColor: MAIN_COLORS.primary,
    },
  },
  settingIconSize: {
    fontSize: 18,
  },
  runBtn: {
    height: '100%',
    position: 'absolute',
    right: '0',
    minWidth: '40px',
  },
  tabs: {
    height: '100%',
    minHeight: '30px',
    marginRight: '60px',
    marginLeft: '40px',
    color: FILE_COLORS.tabsArrowColor,
  },
  selectedTabindicator: {
    height: '1px',
    backgroundColor: MAIN_COLORS.accent,
  },
  tabsScrollBtn: {
    backgroundColor: FILE_COLORS.scrollBtnBgColor,
  },
  popper: {
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 4,
    maxWidth: 400,
    maxHeight: 500,
    minWidth: 200,
    minHeight: 200,
  },
  popperContainer: {
    border: '1px solid',
    backgroundColor: theme.palette.background.paper,
    padding: '20px',
  },
  popperCloseBtn: {
    float: 'right',
    left: '20px',
    top: '-10px',
  },
  editorConfig: {
    clear: 'both',
  },
}));

export default fileStyles;
