import React, {useState} from 'react';

// material-ui
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {createStyles, makeStyles} from '@material-ui/core/styles';
//* End of Imports**************

// styles
const useStyles = makeStyles(() =>
  createStyles({
    form: {
      paddingTop: '10px',
      paddingBottom: '10px',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

//* Interfaces/types**********
type AddFileFormProps = {
  handleNewFile: (fileName: string) => void;
};
//* ***************************

//* Return*********************
const AddFileForm: React.FC<AddFileFormProps> = ({
  handleNewFile,
}: AddFileFormProps) => {
  const classes = useStyles();

  //* State***********************
  const [fileName, setFileName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [errorDesc, setErrorDesc] = useState('');
  //* ****************************

  //* Actions*********************
  const handleChange = (event) => {
    setFileName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (fileName === '') {
      setNameError(true);
      setErrorDesc('Enter a file name');
    } else {
      setNameError(false);
      setErrorDesc('');
      handleNewFile(fileName);
    }
  };
  //* ****************************

  //* Return**********************
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <TextField
        error={nameError}
        id="outlined-error-helper-text"
        label="New file name "
        defaultValue={fileName}
        helperText={nameError && errorDesc}
        variant="outlined"
        onChange={handleChange}
        size="small"
        InputLabelProps={{
          style: {
            fontSize: '13px',
          },
        }}
      />
      <Button type="submit" variant="contained" color="primary">
        Add
      </Button>
    </form>
  );
};

export default AddFileForm;
