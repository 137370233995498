import React, {useEffect, useState} from 'react';

// material-ui
import LinearProgress from '@material-ui/core/LinearProgress';

// api
import loadCreations from '../../api/handleCreations';

// styles
import CreationLayout from './CreationLayout';
import galleryStyles from './galleryStyles';

type GalleryProps = {};

const Gallery: React.FC<GalleryProps> = () => {
  const classes = galleryStyles();

  const [creations, SetCreation] = useState<any>(null);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const loadCreationsT = async () => {
      setShowSpinner(true);
      const data = await loadCreations();
      const creationsArr: any = [];
      data.forEach((creation) => {
        creationsArr.push(
          <CreationLayout creation={creation} key={creation.id} />,
        );
      });
      SetCreation(creationsArr);
      setShowSpinner(false);
    };

    loadCreationsT();
  }, []);

  return (
    <>
      {showSpinner && <LinearProgress />}
      <div className={classes.creationsContainer}>{creations}</div>
    </>
  );
};

export default Gallery;
