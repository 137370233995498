// material-ui
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';

const playgroundStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      overflowY: 'auto',
    },
    editor: {
      backgroundColor: 'red',
      minHeight: '93.5vh',
      height: '93.5vh',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    result: {
      backgroundColor: 'black',
      minHeight: '93.5vh',
      height: '93.5vh',
      [theme.breakpoints.down('xs')]: {
        height: '100vh',
      },
    },
  }),
);

export default playgroundStyles;
