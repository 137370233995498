/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

// material-ui
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import SettingsIcon from '@material-ui/icons/Settings';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CancelPresentationTwoToneIcon from '@material-ui/icons/CancelPresentationTwoTone';

// styles
import fileStyles from './filesStyles';

// components
import AddFileForm from './FileUtils/AddFileForm';
import FilesList from './FileUtils/FilesList';
import EditorConfig from '../EditorUtils/EditorConfig';

//* End of Imports*************

//* Interfaces/types**********
type FilesProps = {
  handleNewFile: (fileName: string) => void;
  deleteFile: (fileName: string) => void;
  handleTabChange: (tabIndex: string) => void;
  sendToTranspile: () => void;
  tabs: any;
  activeTab: string;
  updateTheme: (theme: string) => void;
  updateFontSize: (fontSize: number) => void;
};
//* ***************************

//* Return*********************
const Files: React.FC<FilesProps> = ({
  handleNewFile,
  deleteFile,
  handleTabChange,
  sendToTranspile,
  tabs,
  activeTab,
  updateTheme,
  updateFontSize,
}: FilesProps) => {
  const classes = fileStyles();

  //* Popper**********************
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleAddFile = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const closePopper = () => {
    setAnchorEl(null);
  };
  //* ****************************

  //* State***********************
  const fileNames = new Set();
  //* ****************************

  //* Actions*********************
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    handleTabChange(newValue);
  };

  const handleNewFileT = (fileName: string) => {
    closePopper();
    handleNewFile(fileName);
  };

  const handleRun = () => {
    sendToTranspile();
  };

  const loadTabs = () => {
    // tabs is an object which has Tab components (coming from Editor)
    return Object.values(tabs).map((tab: any) => {
      fileNames.add(tab.key);
      return tab;
    });
  };
  //* ****************************

  //* Return**********************
  return (
    <div className={classes.tabsContainer}>
      <AppBar position="static" color="default" className={classes.tabsAppBar}>
        <div className={classes.root}>
          <Button
            variant="contained"
            color="primary"
            className={classes.settingBtn}
            onClick={handleAddFile}>
            <SettingsIcon className={classes.settingIconSize} />
          </Button>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="file tabs"
            classes={{
              root: classes.tabs,
              indicator: classes.selectedTabindicator,
              scrollButtons: classes.tabsScrollBtn,
            }}>
            {loadTabs()}
          </Tabs>
          <Button
            variant="contained"
            color="primary"
            className={classes.runBtn}
            onClick={handleRun}
            name="runBtn">
            <PlayArrowIcon />
          </Button>
        </div>
      </AppBar>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={classes.popper}>
        <div className={classes.popperContainer}>
          <Button className={classes.popperCloseBtn} onClick={closePopper}>
            <CancelPresentationTwoToneIcon />
          </Button>
          <EditorConfig
            updateTheme={updateTheme}
            updateFontSize={updateFontSize}
          />
          <hr />
          <AddFileForm handleNewFile={handleNewFileT} />
          <FilesList deleteFile={deleteFile} fileNames={fileNames} />
        </div>
      </Popper>
    </div>
  );
};

export default Files;
