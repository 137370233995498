import {makeStyles} from '@material-ui/core/styles';

const authStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    width: '100%',
  },
  actionBtn: {
    padding: 0,
    fontSize: 12,
  },
  resendEmail: {
    padding: 0,
    fontSize: 12,
    marginTop: 10,
  },
  resendEmailMsg: {
    width: '100%',
    marginTop: '10%',
  },
  googleLogin: {
    margin: theme.spacing(0, 0, 2),
  },
}));

export default authStyles;
