import React, {useEffect, useState} from 'react';

// router
import {HashRouter, Route, Switch} from 'react-router-dom';

// material-ui
import {Hidden} from '@material-ui/core';

// styles
import './App.css';

// components
import Navbar from './Components/Navbar/Navbar';
import Playground from './Components/Playground/Playground';
import Auth from './Components/Auth/Auth';
import CustomError from './Components/Common/CustomError';
import Gallery from './Components/Gallery/Gallery';

//* Interfaces/types**********
type AppProps = {};
//* **************************

//* Return*********************
const App: React.FC<AppProps> = () => {
  //* State*********************
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [user, setUser] = useState(false);
  //* **************************

  // Error handling
  // WARNING : Need a better approach, this may cause unwanted re-renders
  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleUserSignIn = (signedIn) => {
    setUser(signedIn);
  };

  const handleCustomError = (errMsgT: string) => {
    setOpenSnackBar(true);
    setErrMsg(errMsgT);
  };

  // WARNING : THIS IS NOT SECURE
  // FIX IT
  useEffect(() => {
    const urlWithCode = window.location.href;
    if (urlWithCode.includes('=')) {
      const inputCode = urlWithCode.split('=')[1];
      if (inputCode.length > 2) {
        // need to decode the base64 url
        localStorage.setItem(
          'java-code',
          atob(
            inputCode.replace(/\./g, '+').replace(/-/g, '=').replace(/_/g, '/'),
          ),
        );
        // eslint-disable-next-line no-restricted-globals
        history.pushState(
          null,
          '',
          window.location.origin + window.location.pathname,
        );

        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    }
    if (localStorage.getItem('user') !== null) {
      setUser(true);
    } else {
      setUser(false);
    }
  }, []);

  return (
    <>
      <HashRouter>
        <Hidden xsDown>
          <Navbar
            handleCustomError={handleCustomError}
            handleUserSignIn={handleUserSignIn}
            user={user}
          />
        </Hidden>
        <Switch>
          {!user && (
            <>
              <Route
                exact
                path="/"
                component={() => <Auth handleUserSignIn={handleUserSignIn} />}
              />
              <Route
                exact
                path="/auth"
                component={() => <Auth handleUserSignIn={handleUserSignIn} />}
              />
            </>
          )}
          {user && (
            <>
              <Route exact path="/" component={() => <Playground />} />
              <Route
                exact
                path="/playground"
                component={() => <Playground />}
              />
              <Route exact path="/gallery" component={Gallery} />
            </>
          )}
        </Switch>
      </HashRouter>
      <CustomError
        openSnackBar={openSnackBar}
        errMsg={errMsg}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </>
  );
};

export default App;
